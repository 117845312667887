<script lang="ts" setup>
type GameItem = {
	slug: string;
	type: string;
};

const props = defineProps<{
	itemSection: number;
}>();

const { t } = useT();
const isGuest = useIsGuest();
const { select } = useGamesState();
const { pageData, lastGames, favoriteGames } = useHomePage({ immediate: false });
const { isMobile } = useDevice();

const games = computed(() => pageData.value?.schemeSections?.[props.itemSection] || []);

const isRecentlyPlayedSection = (game: GameItem) => game?.slug === "last" && !isGuest.value && lastGames.value?.length;
const isFavoriteSection = (game: GameItem) => game?.slug === "favorites" && !isGuest.value && lastGames.value?.length;

const isPrerenderedComponent = (count: number) => count < 2;
</script>

<template>
	<div v-if="games.length" class="section-item">
		<template v-for="(game, index) in games" :key="index">
			<OLazyComponent
				v-if="isRecentlyPlayedSection(game) && lastGames.length > 0"
				:id="`home-game-${game?.slug}`"
				:height="210"
				:width="160"
				:items-count="isMobile ? 12 : 30"
				:rows="1"
				:prerender="itemSection === 0 ? isPrerenderedComponent(index) : false"
			>
				<template #skeleton-title>
					<MSkeletonTitle />
				</template>
				<LazyOHomeGames :games="lastGames" :title="t('Recently Played')" icon="20/clock" :show-view-all="false" />
			</OLazyComponent>
			<OLazyComponent
				v-else-if="isFavoriteSection(game) && favoriteGames.length > 0"
				:id="`home-game-${game?.slug}`"
				:height="210"
				:width="160"
				:items-count="isMobile ? 12 : 30"
				:rows="1"
				:prerender="itemSection === 0 ? isPrerenderedComponent(index) : false"
			>
				<template #skeleton-title>
					<MSkeletonTitle />
				</template>
				<LazyOHomeGames :games="favoriteGames" :title="t('favorites')" category="favorites" icon="20/favorite" />
			</OLazyComponent>

			<OLazyComponent
				v-else-if="!!pageData?.games[camelCase(game?.slug ?? '')]?.length"
				:id="`home-game-${game?.slug}`"
				:height="200"
				:width="160"
				:items-count="isMobile ? 12 : 30"
				:rows="3"
				:prerender="itemSection === 0 ? isPrerenderedComponent(index) : false"
			>
				<template #skeleton-title>
					<MSkeletonTitle />
				</template>
				<LazyOHomeGames
					:games="select(pageData?.games[camelCase(game?.slug ?? '')])"
					:title="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.title || ''"
					:icon="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.logo || ''"
					:category="game?.slug"
					:rows-per-slide="game?.row_count"
					:type="game?.type_component"
					isAdminIcon
				/>
			</OLazyComponent>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.section-item {
	display: flex;
	flex-direction: column;
}
</style>
